:root {
  --background: #ffffff;
  --top: env(safe-area-inset-top);
  --bottom: env(safe-area-inset-bottom);
}

html {
  height: 100%;
  min-height: calc(100% + var(--top));
}

body {
  margin: 0;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

#root {
  height: 100%;
}

#root * {
  scrollbar-width: none;
}

#root *::-webkit-scrollbar {
  display: none;
}

#root *[hidden] {
  display: none !important;
}

#root *[loading] {
  pointer-events: none !important;
}

#root > div {
  min-height: 100%;
  display: flex;
}

#root > div.dialog {
  z-index: 100;
  position: fixed;
  inset: 0;
}

#loader {
  display: none;
  z-index: 101;
  position: fixed;
  top: var(--top);
  left: 0;
  width: 100%;
  height: 2px;
  overflow: hidden;
}

#loaderIndicator {
  width: 96px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: loaderAnimation 0.5s linear infinite alternate;
}

@keyframes loaderAnimation {
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
}

#stripe {
  display: none;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

@media screen and (min-width: 680px) {
  #root > div {
    background: var(--background);
  }

  #logo {
    position: fixed;
    top: calc(var(--top) + 24px);
    left: 24px;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  #logo > img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 8px;
  }

  #logo > div {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  #corner-topLeft,
  #corner-topRight,
  #corner-bottomRight,
  #corner-bottomLeft {
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
  }

  #corner-topLeft {
    top: 0;
    left: 0;
  }

  #corner-topRight {
    top: 0;
    right: 0;
  }

  #corner-bottomRight {
    bottom: 0;
    right: 0;
  }

  #corner-bottomLeft {
    bottom: 0;
    left: 0;
  }

  #corner-topLeft:before,
  #corner-topRight:before,
  #corner-bottomRight:before,
  #corner-bottomLeft:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
  }

  #corner-topLeft:before {
    top: 0;
    left: 0;
    box-shadow: -8px -8px 0 0 var(--background);
  }

  #corner-topRight:before {
    top: 0;
    right: 0;
    box-shadow: 8px -8px 0 0 var(--background);
  }

  #corner-bottomRight:before {
    bottom: 0;
    right: 0;
    box-shadow: 8px 8px 0 0 var(--background);
  }

  #corner-bottomLeft:before {
    bottom: 0;
    left: 0;
    box-shadow: -8px 8px 0 0 var(--background);
  }

  div[id^="screen"] {
    position: relative;
  }

  div[id^="screen"].responsive {
    max-width: calc(100% - 16px);
    max-height: calc(100% - 16px);
    margin: 8px;
  }

  div[id^="screen"].responsiveWithTabBar {
    max-width: calc(100% - 104px);
    max-height: calc(100% - 16px);
    margin: 8px 8px 8px 96px;
  }

  div[id^="screen"].compact {
    max-width: 438px;
    height: 750px !important;
    max-height: calc(100% - 16px);
    box-sizing: border-box;
    margin: auto;
  }

  div[id^="tabBar"] {
    border-top-width: 0 !important;
    border-right-width: 1px !important;
    box-sizing: border-box;
    top: var(--top) !important;
    flex-direction: column;
    height: 100% !important;
    width: 88px !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 24px 0 !important;
    gap: 32px;
  }

  div[id^="tabBar"] > div {
    width: 100% !important;
    gap: 10px !important;
  }

  div[id^="tabBar"] > div > div {
    font-size: 12px !important;
    line-height: normal !important;
  }

  .clickable:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

@media screen and (min-width: 1032px) {
  div[id^="screen"].responsive,
  div[id^="screen"].responsiveWithTabBar {
    max-width: 840px;
    max-height: calc(100% - 120px);
    margin: 60px auto;
  }

  div[id^="screen"].leading {
    max-width: 310px;
    max-height: calc(100% - 120px);
    margin: 60px 5px 60px calc((100% - (310px + 5px + 5px + 520px)) / 2);
  }

  div[id^="screen"].trailing {
    max-width: 520px;
    max-height: calc(100% - 120px);
    margin: 60px calc((100% - (310px + 5px + 5px + 520px)) / 2) 60px 5px;
  }
}

@media screen and (max-width: 1031px) {
  div[id^="screen"].responsive #logo {
    display: none;
  }

  div[id^="screen"].compact #logo > div {
    display: none;
  }
}

@media screen and (min-width: 1480px) {
  div[id^="screen"].leading {
    max-width: 390px;
    margin: 60px 5px 60px calc((100% - (390px + 5px + 5px + 780px)) / 2);
  }

  div[id^="screen"].trailing {
    max-width: 780px;
    margin: 60px calc((100% - (390px + 5px + 5px + 780px)) / 2) 60px 5px;
  }
}
